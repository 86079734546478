<template>
  <v-container
    id="garden"
    tag="section"
  >
    <div class="mypagetitle">花の写真</div>
    <base-interface
      :mysummary="mypagesummary"
      :myaccordion="myaccordionsummary"
      :myphotos="myphotolist"
      pagename="garden1" />
    <div><br></div>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
//import pagesummary from "./pagesummary.json";
//import accordionsummary from "./accordionsummary.json";
//import photolist from "./photolist.json";
  export default {
    name: 'Rooms',
    components: {
      BaseInterface: () => import('@/components/home/interface/BaseInterface.vue'),
    },
    data() {
      return {
        pagesummary: [],
        accordionsummary: [],
        photolist: []
        //gardendata : gardendata,
      };
    },
     mounted() {
    this.fetch_flowers();
  },

    methods: {
          fetch_flowers() {
      fetch('./api/gardendata.php',
      {
        mode: 'cors',
        method: "POST",
        body: JSON.stringify({action:"readflower"})
      })
      .then((response)=> {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        //console.log(data);
        this.pagesummary = data.pagesummary;
        this.accordionsummary = data.accordion;
        //console.log('accordion')
        //console.log(data.accordion)
        this.photolist = data.photolist;
      })
      .catch((error) => {
        //console.log(error);
        this.error = 'Failed to fetch comments. Try again.'
      });
    },
    },


    computed: {

      ...mapGetters( ['stCurrentLang']),
      mylanguage() {
        return this.stCurrentLang
      },
      mypagesummary() {
        //console.log(this.mylanguage)
          switch (this.mylanguage) {
            case 'en': return this.pagesummary.en
            case 'jp': return this.pagesummary.jp
            case 'kr': return this.pagesummary.kr
            case 'cn': return this.pagesummary.cn
          }
      },
      myaccordionsummary() {
        //console.log(this.mylanguage)
        //console.log(this.accordionsummary.en)
          switch (this.mylanguage) {
            case 'en': return this.accordionsummary.en
            case 'jp': return this.accordionsummary.jp
            case 'kr': return this.accordionsummary.kr
            case 'cn': return this.accordionsummary.cn
          }
      },
      myphotolist() {
        return this.photolist;
      },
     names() {
      return gardendata.accounts;
    },

      // 2018spring
      /* old
      mysummary() {
          const {pagesummary} = require('./gardens/garden.js')
          switch (this.mylanguage) {
            case 'en': return pagesummary.en
            case 'jp': return pagesummary.jp
            case 'kr': return pagesummary.kr
            case 'cn': return pagesummary.cn
          }
      },

      myaccordion() {
          const { accordion } = require('./gardens/garden.js')
          switch (this.mylanguage) {
            case 'en': return accordion.en
            case 'jp': return accordion.jp
            case 'kr': return accordion.kr
            case 'cn': return accordion.cn
          }
      },

      myphotos() {
        const { photos } = require('./gardens/garden.js')
        return photos
      },*/
}
  }
</script>
